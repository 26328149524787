import React, {useState,useEffect} from 'react';
import Layout from '../components/layout'
import StyledBackgroundSection from '../components/backgroundimage';


import AvailableUnitsSection from '../components/available-units-section';

import ImageGallery from '../components/image-gallery';

import ContactFormModal from '../components/contact-form-modal'
import { graphql, useStaticQuery } from 'gatsby';

import { Helmet } from "react-helmet";
import colors from '../components/theme';
import BuildingImages from '../components/building-images';
import NeighborhoodMap from '../components/neighborhoodmap';
import VideoPlayer from '../components/videoplayer';


import styled from 'styled-components';
import AlertBanner from '../components/alertbanner';
import MatterportContainer from '../components/matterport-container';
import RoomConfiguration from '../components/roomconfiguration';

import { NorthgatePricing } from '../components/northgate-pricing';

const HeroSection = styled.section`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
  width: 100vw;
  font-family: rubik;
 
`

const VideoPlayerSection = styled.div`

//  margin-top:30px;
 
`


const HeroHeading = styled.h1`
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  font-size: 4em;
  text-align: center;
  font-family: rubik;
  @media (max-width: 768px) {
    font-size: 2em;
  } 
`
const TourSection = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 2.5em;
  font-family: rubik;
 
`
const TourHeading = styled.h3`
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  margin-bottom:0px;
  font-size: 1.5em;
  line-height: 1.7em;
  text-align: center;
  font-family: rubik;
  padding-bottom:12px;
  @media (max-width: 768px) {
    font-size: 1.5em;

  } 
`

const TourSubHeading = styled.h4`
margin-top:0px;
  color: black;
  font-weight: 400;
  font-size: 1.2em;
  text-align: center; 
  line-height: 1.5em;
  @media (max-width: 850px){
    text-align: center; 
  }
  @media (max-width: 768px) {
    font-size: 1em;
    width: 75%;
    text-align: center`

    const TourButton = styled.button`
    border-radius: 5px;
    border: none;
    box-shadow: lightgrey 1px 2px 3px;
    font-family: rubik;
    text-transform: uppercase;
    font-weight: 200;
    color: black;
    background-color: rgb(212, 244, 239);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    width: 30%;
    height: 50px;
    // margin: 1em;
    // text-transform: uppercase;
    // font-weight: 200;
    // background-color: ${colors.ctapink};
    // font-family: rubik;
    // color: #fff;
    // cursor: pointer;
    // border-radius: 30px;
    // z-index: 4;
    @media(max-width: 425px){
        display: block;
        width: 100%;
    }
    `

const HeroSubheading = styled.h3`
  color: white;
  font-weight: 400;
  
  font-size: 2em;
  @media (max-width: 850px){
    text-align: center; 
  }
  @media (max-width: 768px) {
    font-size: 1em;
    width: 75%;
    text-align: center`

const VirtualTourSection = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 50px 0px 50px 0px;
flex-direction: column;
position: relative;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23d4f4ef' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

`

const VirtualTourTitle = styled.div`
    font-family: rubik;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    margin: auto;
    font-size: 30px;
    padding: 20px 0px 20px 0px;
    
   
    @media(max-width: 1440px){
        font-weight: 700;
        font-family: rubik;
        text-align: center;
        position: relative;
        font-size: 20px;
        top: 0;
        left: 0;
        text-align: center;
        margin: 1em 0 0.25em 0;
    }
`

const VirtualTourContainer = styled.div`
display: flex;
overflow: hidden;
position: relative;
background-color: ${colors.gray};
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 5px;
box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.25);

`

const ContentView = styled.div`
  display: block;
  padding: 10px;
  margin: 100px 40px;
  margin-bottom: 0;
  @media(max-width: 767px){
    margin: 60px 0;
    margin-bottom: 0;
  }
`

const ContentHeader = styled.h3`
  display: block;
  color: black;
  font-weight: 700;
  font-family: 'rubik', sans-serif;
  font-size: 2em;
  margin-bottom: .5em;
  margin-top: 0;
`

const ContentSeparator = styled.div`
  display: block;
  height: 2px;
  background: black;
  width: 100%;
`
const ContentBody = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
  padding: 1.5em 0;
  
  @media(max-width: 1024px){
    grid-template-columns: 100%;
    grid-row-gap: 1.5em;
  }
`
const ContentPara = styled.div`
  font-size: 1.2em;
  line-height: 1.4;
  font-weight: 300;
  font-family: 'rubik', sans-serif;
`

const ContentAction = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const ContentCtaButton = styled.button`
    height: 40px;
    min-width: 200px;
    border-radius: 5px;
    border: none;
    box-shadow: 1px 2px 3px lightgray;
    font-family: rubik;
    text-transform: uppercase;
    font-weight: 200;
    color: black;
    background-color: ${colors.blue};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    &:focus {
        outline: 0;
    }
`

const GridGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 28px;
  margin: 0 40px;
  margin-bottom: 2em;
  @media(max-width:1025px){
    grid-template-columns: repeat(2, 1fr);
    margin: 0 10px;
    margin-bottom: 30px;
  }
  @media(max-width:767px){
    grid-template-columns: repeat(1, 1fr);
    margin: 0 10px;
    margin-bottom: 30px;
  }
`

const GalleryImageContainer = styled.div`
  display: block;
`
const GalleryImage = styled.div`
  background-position: center;
  background-size: cover;
  display: block;
  width: 100%;
  height: 268px;
`

const GalleryImageTitle = styled.h5`
  font-size: 16px;
  font-family: 'rubik', sans-serif;
  margin-top: 1em;
  margin-bottom: .5em;
  font-weight: 400;
`

const ValueTableContainer = styled.div`
  padding: 10px;
  max-width: 800px;
  margin: 0px auto;
  margin-bottom: 0px;
  overflow-x: auto;
  @media(max-width: 767px)
  {
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;
  }
`

const NeighborhoodRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
  margin: 0 40px;
`

const NeighborhoodImage = styled.div`
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 268px;
`
const NeighborhoodImgTitle = styled.label`
  font-family: 'rubik', sans-serif;
  font-size: 1rem;
  font-weight: 200;
`

const FloatingCTA = styled.button`
  position: fixed;
  display: none;
  width: 100%;
  color: #fff;
  bottom: 0;
  right: 0;
  background: #232323;
  color: #FFF;
  border: none;
  padding: 16px 0;
  font-family: 'rubik', sans-serif;
  text-transform: uppercase;
  justify-content: center;
  box-shadow: 0px 0px 19px -6px #00000070;
  z-index: 99;
  @media(max-width: 1000px){
    display: flex;
  }
`

const SmallText = styled.p`
  font-size: 11px;
  color: #989898;
  font-family: 'rubik', sans-serif;
  font-weight: 200;
  padding-left: 16px;
  font-style: italic;
  width: 800px;
  margin: 0 auto;
`

const amenities_imgs = [
    {
        title : 'Luxer One',
        img : 'https://images.ctfassets.net/35n597rqam52/5CAzfDzrh5VmJ7d1qMokfX/6cadd44028b969c8b0df3b52f35e0de2/luxerOne.jpg'
    },
    {
        title : 'High-end Kitchen',
        img : 'https://images.ctfassets.net/35n597rqam52/5MjNcAUpAOXYLYpLaOfIZK/17b8a5e91ee76a007a41142a752f771c/High-End_Kitchen.jpg'
    },
    {
        title : 'Fully Furnished',
        img : 'https://images.ctfassets.net/35n597rqam52/69OoK7yaNx7yAMxneK1njy/43e6a89df2ea4fbb73e236503b6799dd/Fully_Furnished.jpg'
    },
    {
        title : 'Thoughtful Design',
        img : 'https://images.ctfassets.net/35n597rqam52/4kmizQoeSinBQCHPCNHaxq/1cae676d714917404f9c8b7bc3e08106/Thoughtful_Design.jpg'
    },
    {
        title : 'Arrival Ready',
        img : 'https://images.ctfassets.net/35n597rqam52/1gnUSsY5qirM26D2023ULT/95e3a50183df7a7df374c0a07acd28b8/Arrival_Ready.jpg'
    },
    {
        title : 'Privacy',
        img : 'https://images.ctfassets.net/35n597rqam52/JyrbnIFDVK6Mv1at2NpBG/329ab7c92588a37a836a9e0790e7bc41/Privacy.jpg'
    }

]

const neighborhood_imgs = [
  {
    title: 'Kilovolt Coffee (Coffee shop)',
    img: 'https://images.ctfassets.net/35n597rqam52/5riRP630dpl87itRfOT0Vh/483f634e9277d90c6c2593e3d89a4ce9/kilovolt02.jpg'
  },
  {
    title: 'Ghost Town Brewing (Brewing company)',
    img: 'https://images.ctfassets.net/35n597rqam52/3tplNnkWqmghRBE1gYtrvr/bf53aa058dcc618b80d2692153923e01/40092310410_1571298bfc_k-1.jpg'
  },
  {
    title: 'Bay Street (Shopping center/Area)',
    img: 'https://images.ctfassets.net/35n597rqam52/5iEiJODWycsL61r3OUvj2T/0be7e5d17bdd9e149370d8fc4e01cad4/rsz_1bayst1.jpg'
  }
]

const bestValue = [
  { 
    title : 'Average Rent',
    data: ['$1,478', '$1,620', '$2,240']
  },
  { 
    title : 'Cleaning',
    data: ['$100', '$120', '$240']
  },
  { 
    title : 'Wifi',
    data: ['Included', '$40', '$70']
  },
  { 
    title : 'Smart TV',
    data: ['Included', '-', '-']
  },
  { 
    title : 'Furniture',
    data: ['Included', '-', '-']
  },
  { 
    title : 'Common Areas',
    data: ['Included', '-', '-']
  },
  { 
    title : 'Fully stocked kitchen',
    data: ['Included', '-', '-']
  },
  { 
    title : 'Support and maintenance',
    data: ['Included', '-', '-']
  },
  { 
    title : 'Community Fee',
    data: ['$95', '(Utilities $60)', '(Utilities $60)']
  },
  { 
    title : 'Parking',
    data: ['(Optional)', '-', '-']
  },
  { 
    title : 'Total',
    data: ['$1,673', '$1,841', '$2,610']
  },
  
]

const TwentySixTwentyFourMLKLander = () => {

    //query all 18th and linden photo srcs
    const imgData = useStaticQuery(graphql`
    query MLKImagesLander {
      allContentfulUnit(filter: {
        property: { 
          propertyName: {
            eq: "27th & Northgate" 
          }
        }
      }) {
        edges {
          node {
            floor
            id
            size
            baseRent
            unitNumber
            vaultedCeilings
            patio
            balcony
            updatedAt
            dateAvailable
            unitLayout {
              fluid(quality:100){
                ...GatsbyContentfulFluid_withWebp
              }
            }
            unitThumbnail {
              fixed(width: 400, height: 400, quality: 100) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
            property {
              propertyName
            }
            unitPhotos {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
      allContentfulProperty(filter: {propertyName: {eq: "27th & Northgate"}}) {
        edges {
          node {
            commonAreaImages {
              fluid(quality: 50, maxWidth: 2000) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            heroImage {
              fluid(quality: 100, maxWidth: 2000){
                ...GatsbyContentfulFluid_withWebp
              }
            }
            exteriorPhotos {
              fluid(quality: 100, maxWidth: 2000){
                ...GatsbyContentfulFluid_withWebp
              }
            }
            roomConfigurations {
              fluid(quality: 50, maxWidth: 1000){
                ...GatsbyContentfulFluid_withWebp
              }
            }
            neighborhoodMap {
              fluid(quality: 100, maxWidth: 1000){
                ...GatsbyContentfulFluid_withWebp
              }
            }

            
          }
        }
      }
    }
      `)


    


    const [galleryModalOpen, setGalleryModalOpen] = useState(false);
    const [contactModalDropDownOpen, setcontactModalDropDownOpen] = useState(false);
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [selectedUnit, setSelectedUnit] = useState("");
    const [filteredImages, setFilteredImages] = useState([]);
    const [selectedUnitDetails, setSelectedUnitDetails] = useState(
        {building: 'unknown',
    sqft: 'unknown',
 });



 const onTourClickHandler = () => {
  setSelectedUnit("");
  setContactModalOpen(true)
} 

  const test='The Northgate ';
  const hidemenu=true;
  const northgatelander = true;
 
console.log('rsg', imgData.allContentfulUnit)

  const con_var = [1, 3]

    return (
      <>
        <Helmet>
          <title>Square One - 27th &amp; Northgate</title>
          <meta name="title" content="Square One - 27th &amp; Northgate" />
          <meta name="description" content="Comprised of a series of townhouse communities, each with private studios and a spacious, central lounge and kitchen, The Northgate will have you immediately feeling at home, immersed in thoughtful design that is equal parts functional and inspiring."/>
          <link rel="canonical" href="http://squareone-living.com/northgate" />
          
          </Helmet>
        <Layout hidemenu={hidemenu}>
            <ImageGallery concessionLength={con_var} setContactModalOpen={setContactModalOpen} selectedUnitDetails={selectedUnitDetails} setFilteredImages={setFilteredImages} filteredImages={filteredImages} setIsOpen={setGalleryModalOpen} isOpen={galleryModalOpen} unit={selectedUnit} imgData={imgData} northgatelander={northgatelander} pricing={NorthgatePricing} />
            <ContactFormModal propertyName={"The Northgate"} setIsOpen={setContactModalOpen} isOpen={contactModalOpen} unit={selectedUnit} allUnits={imgData.allContentfulUnit.edges} northgatelander={northgatelander}/>
            {/* <ContactFormModalDropdown propertyName={"The Northgate"} setIsOpen={setcontactModalDropDownOpen} isOpen={contactModalDropDownOpen} unit={test} allUnits={imgData.allContentfulUnit.edges}/> */}
              <StyledBackgroundSection fluid={ imgData.allContentfulProperty.edges[0].node.heroImage.fluid || null }>
                <HeroSection>
                  <HeroHeading>27th &amp; Northgate</HeroHeading>
                </HeroSection>
                  
              </StyledBackgroundSection >

              <TourSection>
                <TourHeading>
                Thoughtfully designed living spaces with hotel-style services, built-in community, and extraordinary amenities
                </TourHeading>
                <TourSubHeading>Epicenter of Oakland | Sizes ranging from 203 - 297 Sq. Ft. | Spaces starting at $1358/month</TourSubHeading>
              
                <ContentCtaButton onClick={onTourClickHandler}>Book Your Tour</ContentCtaButton>


                {/* <TourButton id="booktour-hero" onClick={()=> onTourClickHandler()}>Book Your Tour</TourButton> */}
                {/* <TourSubHeading>Move Your Business Forward</TourSubHeading> */}
              </TourSection>

              <VideoPlayerSection>
                <VideoPlayer className="vid-player" url="https://vimeo.com/468242797"/>
              </VideoPlayerSection>

            <ContentView>
                <ContentHeader>
                  Welcome to 27th & Northgate
                  <small style = {{width : '100%', display : 'none', fontSize : '20px', marginTop : '8px'}}>Coliving apartments that offer the best of design and function.</small>
                </ContentHeader>
                <ContentSeparator/>
                <ContentBody>
                    <ContentPara>
                    <p style={{marginTop: 0}}>
                      Built just this year, 27th & Northgate is at the very center of Oakland’s vibrant arts, music and culture community. Comprising of eight 3-story townhouse communities, each with five studios and a spacious central lounge and kitchen, 27th & Northgate will have you immediately feeling at home with its thoughtful design that is equal parts both functional and inspiring.
                    </p>
                    <p style={{marginBottom: 0}}>
                      The location too is ideal, whether you are looking for a walkable commute during the week or are headed towards downtown Oakland or to San Francisco via BART.
                    At 27th & Northgate, you get the best of both worlds, a warm community that champions inclusion and individuality as well as the freedom to retreat to your well-appointed studio that includes a private full bathroom, for an exemplary standalone studio experience.
                    </p>
                    </ContentPara>
                    <ContentAction>
                        <ContentCtaButton onClick={onTourClickHandler}>Book Your Tour</ContentCtaButton>
                    </ContentAction>
                </ContentBody>
            </ContentView>

            <AvailableUnitsSection  concessionLength={con_var} selectedUnit= {selectedUnit} setSelectedUnitDetails={setSelectedUnitDetails} setSelectedUnit={setSelectedUnit} setGalleryModalOpen={setGalleryModalOpen} imgData={imgData} setContactModalOpen={setContactModalOpen}>
                
            </AvailableUnitsSection>
            {/* <BuildingSection /> */}
            
            <RoomConfiguration isNorthgate = {true} propertyName={"The Northgate"}  unit={test} allUnits={imgData.allContentfulUnit.edges} setContactModalOpen={setContactModalOpen} northgatelander={northgatelander} images={imgData && imgData.allContentfulProperty.edges[0].node.roomConfigurations} setContactModalOpen={setContactModalOpen}  setSelectedUnit={setSelectedUnit}/>

            <ContentView style = {{marginBottom : '40px'}}>
                <ContentHeader>
                  All that you need. And more!
                </ContentHeader>
                <ContentSeparator/>
                <ContentBody>
                    <ContentPara>
                      These newly remodeled apartments offer you plenty of space and natural sunlight. With a fully stocked kitchen, furniture, smart tv, and WIFI, just to mention a few, you have all the essentials and more. Add to that, elegant outdoor spaces and you have a home that’s designed to stun!
                    </ContentPara>
                    <ContentAction>
                        <ContentCtaButton onClick={onTourClickHandler}>Book Your Tour</ContentCtaButton>
                    </ContentAction>
                </ContentBody>
            </ContentView>
            <GridGallery style = {{marginBottom : '40px', marginTop : '40px'}}>

                {
                    amenities_imgs.map((val, index)=>{
                        return (
                            <GalleryImageContainer>
                                <GalleryImage style={{backgroundImage : `url(${val.img})`}} />
                                <GalleryImageTitle>
                                    {val.title}
                                </GalleryImageTitle>
                            </GalleryImageContainer>
                        )
                    })
                }


            </GridGallery>
                <style jsx>{
                  `
                    #best-value-tbl{
                      width: 800px;
                      padding: 0 16px;
                      font-family: 'rubik', sans-serif;
                    }
                    #best-value-tbl :is(th, td) {
                        padding: 16px 0px;
                        border-bottom: solid 1px #eeeeee;
                    }
                    #best-value-tbl tr:not(:last-child) :is(th, td):nth-of-type(2)
                    {
                      border-bottom: solid 3px #eeeeee;
                    }
                    #best-value-tbl :is(th, td):nth-of-type(2)
                    {
                      background: #f5f5f5;
                    }
                    #best-value-tbl tr:last-child td:nth-of-type(2)
                    {
                      background: rgb(212, 244, 239);
                      color: black;
                    }
                    #best-value-tbl tr:last-child
                    {
                      font-weight: bold;
                    }
                    #best-value-tbl :is(th, td):not(:first-child)
                    {
                      text-align: center;
                    }
                    #best-value-tbl tr:not(:last-child) td
                    {
                      font-size: 14px;
                    }
                    #best-value-tbl th:first-child
                    {
                      text-align: left;
                      font-weight: bold;
                    }
                    @media(max-width: 767px)
                    {
                      #best-value-tbl tr :is(th:first-child, td:first-child) {
                        position: -webkit-sticky;
                        position: sticky;
                        left: 0;
                        z-index: 2;
                        background: #FFF;
                        border-right: solid 2px #e6dfdf;
                      }
                      #best-value-tbl tr th:first-child {
                        width: 20%;
                      }
                      
                      #best-value-tbl tr th:nth-of-type(2) {
                          width: 24%;
                      }
                      .best-value {
                        margin-top: 40px !important;
                      }
                    }
                  `
                }
                </style>
                <TourHeading className = "best-value" style={{fontWeight: 'bold', marginTop : '100px'}}>
                  BEST VALUE IN TOWN
                </TourHeading>
            <ValueTableContainer>
              <table id="best-value-tbl">
                <thead>
                  <tr>
                    <th>
                      Bay Area
                    </th>
                    <th>
                      27th & Northgate
                    </th>
                    <th>
                      Craigslist Room
                    </th>
                    <th>
                      Traditional Studio
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    bestValue.map((values, index)=>{
                      return (
                        <tr>
                          <td>
                            {values.title}
                          </td>
                          <td>
                            {values.data[0]}
                          </td>
                          <td>
                            {values.data[1]}
                          </td>
                          <td>
                            {values.data[2]}
                          </td>
                        </tr>
                      )
                    })
                  }
                  
                </tbody>
              </table>
            </ValueTableContainer>
              <SmallText>*Optional charges: Parking fee $175 (if available)</SmallText>
              <ContentAction style = {{marginTop : '30px', marginBottom : '30px'}}>
                  <ContentCtaButton onClick={onTourClickHandler}>Book Your Tour</ContentCtaButton>
              </ContentAction>

            <ContentView>
                <ContentHeader style={{fontSize : '2em'}}>
                  Neighborhood
                </ContentHeader>
                <ContentSeparator/>
                <ContentBody>
                    <ContentPara style = {{gridColumnStart : 1, gridColumnEnd : 3}}>
                      A ten-minute BART ride to San Francisco's Financial District. A ten-minute bike-ride to Uptown Oakland or Emeryville. Centrally located to jobs, nightlife, and shopping - West Oakland sits in the middle of it all. Boasting the BART network's busiest and most connected station, West Oakland is your gateway to the city, the East Bay, the Peninsula and Silicon Valley. Sitting just to the north of West Oakland is Emeryville's busting big box retail corridor, where you can shop at one of the region's few Ikea supercenters, get your necessities at Target, or have a night out on the town at Bay Street and its growing cousin, the Public Market. To the east, Oakland's hip Uptown District shines with some of the city's best dining and nightlife - don't miss First Fridays, Telegraph Avenue's monthly block party! To the south and west, the fun continues in Oakland's downtown, seamlessly transforming into bustling Jack London Square with its panoply of urban wineries. There's so much to see and do on the sunny side of the Bay!
                    </ContentPara>
                </ContentBody>

            </ContentView>

                <GridGallery style ={{marginTop: '40px'}}>

                {
                    neighborhood_imgs.map((val, index)=>{
                        return (
                            <GalleryImageContainer>
                                <GalleryImage style={{backgroundImage : `url(${val.img})`}} />
                                <GalleryImageTitle>
                                    {val.title}
                                </GalleryImageTitle>
                            </GalleryImageContainer>
                        )
                    })
                }


                </GridGallery>
                  
                <ContentAction style = {{marginTop : '30px', marginBottom: '70px'}}>
                    <ContentCtaButton onClick={onTourClickHandler}>Book Your Tour</ContentCtaButton>
                </ContentAction>

                <FloatingCTA onClick={onTourClickHandler}>
                  Book Your Tour
                </FloatingCTA>

            {/* <NeighborhoodMap 
            propertyName={"The Northgate"}  unit={test} allUnits={imgData.allContentfulUnit.edges} setContactModalOpen={setContactModalOpen} 
            image={imgData.allContentfulProperty.edges[0].node.neighborhoodMap} northgatelander={northgatelander} setSelectedUnit={setSelectedUnit}
            neighborhoodBlurb="Built new this year, The Northgate sits at the epicenter of Oakland’s vibrant arts, music, and culture community.
            Nestled in a quiet neighborhood just six blocks from the iconic Fox Theatre and the legendary nightlife of Broadway, 
           the Linden provides the ideal location for a walkable commute during the week, whether you’re headed to downtown Oakland 
           or to San Francisco via BART, and convenient access to everything downtown Oakland has to offer.">
              
              </NeighborhoodMap> */}
            {/* <BuildingImages images={imgData && imgData.allContentfulProperty.edges[0].node.exteriorPhotos}/> */}
            {/* <VirtualTourSection>
              <VirtualTourContainer>
                <VirtualTourTitle>Check out the Space in 3D!</VirtualTourTitle>
                <MatterportContainer tourId={"beDvxH3pas2"}/>
              </VirtualTourContainer>
            </VirtualTourSection> */}
            
                
            {/* <GalleryGrid photos = {imgData.allContentfulProperty.edges[0].node.commonAreaImages} setIsLightBoxOpen={setIsLightBoxOpen} setPhotoIndex={setPhotoIndex}/>
            {isLightBoxOpen && <LightBox 
            images={imgData.allContentfulProperty.edges[0].node.commonAreaImages}
            photoIndex={photoIndex}
            isOpen={isLightBoxOpen}
            setPhotoIndex={setPhotoIndex}
            setIsOpen={setIsLightBoxOpen}
            
            /> */}
            
            {/* <Amenities /> */}
            
        </Layout>
        </>
        
    )
}


export default TwentySixTwentyFourMLKLander;